import { windowDimensionsType } from "./../helpers/index";
import { useState, useEffect } from "react";
import { getWindowDimensions } from "../helpers";

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] =
    useState<windowDimensionsType>(() => getWindowDimensions());

  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
