import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

const Container = styled(Box)`
  position: absolute;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
`;

const NavMenuButton = styled(HamburgerIcon)`
  cursor: pointer;
`;

export { Container, NavMenuButton };
