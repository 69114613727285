import { Dispatch } from "react";
import { Subject, Subscription } from "rxjs";

export type navStateType = {
  isMenuOpen: boolean;
};

export interface INavigationStore {
  init: () => void;
  subscribe: (setState: Dispatch<any>) => Subscription;
  setNavigationState: (navState: object) => void;
  initialState: navStateType;
}

const subject = new Subject();

const initialState: navStateType = {
  isMenuOpen: true,
};

let state = initialState;

const navigationStore: INavigationStore = {
  init: () => subject.next(state),
  subscribe: (setState) => subject.subscribe(setState),
  setNavigationState: (navState) => {
    state = {
      ...state,
      ...navState,
    };
    subject.next(state);
  },
  initialState,
};

export { navigationStore };
