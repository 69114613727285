import { useEffect } from "react";
import { navigateToUrl } from "single-spa";
import { useTheme } from "@chakra-ui/react";

import { NAVIGATION_ITEMS } from "../../constants/navigation-items";
import useNavState from "../../hooks/useNavState";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import * as Navigation from "./styles";

const LeftHandNavigation = () => {
  const [{ isMenuOpen }, navigationStore] = useNavState();
  const { width } = useWindowDimensions();
  const { breakpointsPX } = useTheme();

  const singleSpaNavigation =
    (path: string) =>
    (e: React.MouseEvent): boolean => {
      e.preventDefault();
      navigateToUrl(path);
      return false;
    };

  useEffect(() => {
    if (width < breakpointsPX.md) {
      navigationStore.setNavigationState({
        isMenuOpen: false,
      });
    }
  }, [width, breakpointsPX.md, navigationStore.setNavigationState]);

  return (
    <Navigation.Container isOpen={isMenuOpen} bg="brand.dark">
      {isMenuOpen &&
        NAVIGATION_ITEMS.map(({ name, path }) => (
          <Navigation.Link
            key={name}
            onClick={singleSpaNavigation(path)}
            href={path}
            color="brand.primary"
          >
            {name}
          </Navigation.Link>
        ))}
    </Navigation.Container>
  );
};

export default LeftHandNavigation;
