export type windowDimensionsType = {
  width: number;
  height: number;
};

const getWindowDimensions = (): windowDimensionsType => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export { getWindowDimensions };
