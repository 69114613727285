import useNavState from "../../hooks/useNavState";
import * as Header from "./styles";

const NavHeader = () => {
  const [{ isMenuOpen }, navigationStore] = useNavState();

  const handleOnNavMenuClick = (): void => {
    navigationStore.setNavigationState({
      isMenuOpen: !isMenuOpen,
    });
  };

  return (
    <Header.Container pr={2} pl={2} bg="brand.darker">
      <Header.NavMenuButton
        role="button"
        onClick={handleOnNavMenuClick}
        h={6}
        w={6}
        mr={2}
        ml={2}
        color="white"
      />
    </Header.Container>
  );
};

export default NavHeader;
