import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import Root from "./root.component";
import useNavState from "./hooks/useNavState";
import { navigationStore } from "./store";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <div>An error as ocurred!</div>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
export { useNavState, navigationStore };
