import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { chakra, Box } from "@chakra-ui/react";

import { animations } from "@thucydides/app-utils";

const closedMenu = css`
  display: none;
`;

const openMenu = css`
  animation: ${animations.slideIn} ease-in-out 0.2s;
`;

const Container = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 175px;
  min-height: 100vh;
  height: calc(100% - 40px);
  margin-top: 40px;
  padding: 8px;
  ${({ isOpen }: { isOpen: boolean }) => (isOpen ? openMenu : closedMenu)};
`;

const Link = chakra("button", {
  baseStyle: {
    size: "small",
    variant: "link",
  },
});

export { Container, Link };
