import { ChakraProvider } from "@chakra-ui/react";
import { THEME } from "@thucydides/app-components";

import LeftHandNavigation from "./modules/leftHandNavigation";
import NavHeader from "./modules/topHeader";

export default function RootNavigation() {
  return (
    <ChakraProvider theme={THEME}>
      <>
        <NavHeader />
        <LeftHandNavigation />
      </>
    </ChakraProvider>
  );
}
