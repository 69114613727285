import { INavigationStore } from "./../store/index";
import { useState, useEffect } from "react";

import { navigationStore, navStateType } from "../store";

const useNavState = (): [navStateType, INavigationStore] => {
  const [navState, setNavState] = useState<navStateType>(
    navigationStore.initialState
  );

  useEffect(() => {
    navigationStore.subscribe(setNavState);
    navigationStore.init();
  }, []);

  return [navState, navigationStore];
};

export default useNavState;
